<template>
  <div class="container w-100 d-flex flex-column align-items-center justify-content-center">
    <div
      class="card w-100 d-flex flex-column flex-sm-row align-items-center justify-content-center p-4 p-xl-5"
      style="border-radius: 24px;"
      v-if="selectedQuestion.length > 0"
    >
      <div class="row">
        <div
          class="col-12 col-md-5 p-0 d-flex flex-column align-items-center justify-content-center "
        >
          <div class="d-flex align-items-center justify-content-center">
            <img src="../../../assets/img/surveyimage.png" alt="" :class="$style['img-banner']" />
          </div>
        </div>
        <div
          class="col-12 col-md-7 p-0 d-flex flex-column align-items-center justify-content-center "
        >
          <P class="text-center my-5 px-4" :class="$style['banner-title']">
            <strong
              ><p>{{ selectedQuestion[0]?.question }}</p></strong
            >
          </P>

          <div class="row w-100 mb-4">
            <div
              class="col-12 col-lg-6 my-3 d-flex flex-column align-items-center justify-content-center "
              style="height: 50px;"
              v-for="(response, idx) in selectedQuestion[0].responses"
              :key="idx"
            >
              <a-button class="w-100 h-100" @click="handleSelectedAnswer(response)">
                <p class="m-0" style="font-size: 13px;">{{ response.answer }}</p>
              </a-button>
            </div>
          </div>
          <div class="row w-100 text-center">
            <div class="col-12">
              <a-button
                type="primary"
                html-type="submit"
                :class="$style['custom-button-yes']"
                :loading="loading"
                @click="handleResponse(selectedAnswer)"
                :disabled="!selectedAnswer"
              >
                <strong>LANJUTKAN</strong>
              </a-button>
            </div>
            <!-- <div class="col-6 ">
          <a-button
            type="primary"
            html-type="submit"
            :class="$style['custom-button-no']"
            :loading="loading"
          >
            <strong>TUTUP</strong>
          </a-button>
        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Radio, Button } from 'ant-design-vue'
import { ref, onMounted, onBeforeMount } from 'vue'
import { getPatientProgram, getSurvey, submitSurvey } from '../../../services/axios/pulih'
import { useRoute, useRouter } from 'vue-router'
export default {
  components: {},
  setup() {
    const selectedQuestion = ref('')
    const surveyData = ref('')
    const patientId = ref('')
    const route = useRoute()
    const router = useRouter()
    onBeforeMount(async () => {
      console.log('disini')
      const userData = JSON.parse(localStorage.getItem('userData'))
      const res = await getPatientProgram(userData.user.code)
      const response = await getSurvey(res.programs[0].programType.id)
      if (!response.length) {
        router.push('/')
      }
      console.log(response, 'iniresp')
      patientId.value = res.id
      surveyData.value = response
      selectedQuestion.value = response.filter(e => e.isFirst)
      console.log(selectedQuestion.value, 'iniSelectedQuesition')
    })
    const selectedAnswer = ref('')
    const handleSelectedAnswer = answer => {
      console.log('inianswer', answer)
      selectedAnswer.value = answer
    }
    const handleResponse = async response => {
      if (response.nextQuestion) {
        selectedQuestion.value = surveyData.value.filter(e => e.id === response.nextQuestion.id)
      } else {
        const res = await submitSurvey(response.id, patientId.value)
        if (res) {
          router.push('/dashboard')
        }
      }
    }
    return {
      selectedAnswer,
      handleSelectedAnswer,
      selectedQuestion,
      handleResponse,
    }
  },
}
</script>

<style lang="scss" module>
@import '@/components/Auth/style.module.scss';

.main-menu-item {
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.main-menu-item:hover {
  transform: scale(1.05);
  /* Slightly increase the size */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.24);
  /* Optionally enhance the shadow */
}

.title-section {
  font-size: 20px;
  color: #4f125f;
  width: 200px;
}

.custom-card {
  position: relative;
  overflow: hidden;
  /* Memastikan isi tidak melebihi batas card */
  border-radius: 17px;
}

.custom-card img {
  width: 100%;
  height: auto;
  /* Sesuaikan dengan kebutuhan, bisa juga height: 100% jika perlu */
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, #800152, transparent);
  width: 100%;
  height: 100%;
  /* Atur sesuai kebutuhan untuk efek gradient */
  z-index: 1;
}

.card-title {
  position: absolute;
  bottom: 10px;
  left: 30px;
  color: white;
  z-index: 2;
  /* Pastikan teks berada di atas overlay */
  width: 100%;
  /* Sesuaikan lebar teks */
}

/* Menargetkan elemen checkbox internal secara global */
:global(.ant-checkbox-inner) {
  width: 20px !important;
  /* Mengatur lebar */
  height: 20px !important;
  /* Mengatur tinggi */
  border-radius: 3px !important;
  /* Opsional: Menghapus border-radius */

  /* Center horizontal */
  border: 1px solid #707072 !important;
}

/* Opsi tambahan: Menyesuaikan ukuran tanda centang jika perlu */
:global(.ant-checkbox-inner::after) {
  /* Atur lebar tanda centang */
  // width: 20px !important;
  // height: 20px !important;
  /* Atur tinggi tanda centang */
  /* Pusatkan secara vertikal */
  // border-radius: 8px !important;
  display: flex !important;
  /* Menggunakan flexbox untuk align centang */
  align-items: center !important;
  /* Center vertical */
  justify-content: center !important;
  // top: 20px !important;
  left: 5px !important;
  // transform: scale(1) !important;
  // rotate: 40deg;
  /* Menyesuaikan posisi dan skala */
}

.custom-input-email-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;

  &:focus {
    border-bottom: 1px solid #800152 !important;
  }

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-input-password-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;

  &:focus-within {
    border-bottom: 1px solid #800152 !important;
  }

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-forget-pass {
  color: #800152 !important;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-register-link {
  color: #800152 !important;
  font-weight: 700;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-form-item {
  label::before {
    content: '' !important;
  }
}

.custom-button-yes {
  width: 100%;
  height: 40px !important;
  font-weight: 500;
  font-size: 12px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}

.custom-button-no {
  // width: 100%;
  height: 20px !important;
  font-weight: 500;
  font-size: 8px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #800251, #ba0672) !important;
  transition: all 0.3s ease-in-out;
  border: 1px solid #800251 !important;

  &:global(.ant-btn-primary:hover) {
    border: 1px solid #800251 !important;
  }

  &:hover {
    color: #800251 !important;
    background: transparent !important;
    border: 1px solid #800251 !important;
  }
}

.custom-container-login {
  width: 75% !important;
}

.custom-text-login {
  color: white;
}

@media (max-width: 960px) {
  .custom-container-login {
    width: 100% !important;
  }
}

.step-title {
  font-size: 18px;
}

.img-banner {
  width: 130px;
  height: auto;
  object-fit: cover;
}

.banner-title {
  font-size: 14px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  background-color: #4f4f4f;
  height: 1px;
}

.img-step {
  width: 32px;
  height: 32px;
}

.step p {
  font-size: 8px;
}

.img-clock {
  width: 38px;
  height: 35px;
}

.konsumsi-section {
  height: 70px;
}

.img-obat {
  width: 27px;
  height: 46px;
}

.text-obat {
  font-size: 12px;
}

.konsumsi-kanan {
  background-color: #ffffff;
  border-radius: 0 20px 20px 0;
  padding: 20px 30px 20px 30px;
}

.article-item {
  width: 280px;
  height: 130px;
  border-radius: 20px;
  margin-top: 10px;
}

.menu-title {
  font-size: 10px;
  height: auto;
}

//DEKSTOP
@media screen and (min-width: 1440px) {
  .main-menu-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 30px;
  }

  .title-section {
    font-size: 32px;
    color: #4f125f;
    width: auto;
  }

  .img-banner {
    width: 327.75px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
  }

  .banner-title {
    font-size: 36px;
    width: 100%;
  }

  .custom-button-yes {
    height: 60px !important;
    width: 100%;
    font-size: 24px !important;
  }

  .custom-button-no {
    height: 60px !important;
    width: 100%;
    font-size: 24px !important;
    // margin-top: 5px;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
  }

  .line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    background-color: #4f4f4f;
    height: 1px;
  }

  .img-step {
    width: 64px;
    height: 64px;
  }

  .step p {
    font-size: 16px;
  }

  .title-step {
    height: 240px;
  }

  .step-title {
    font-size: 32px;
    width: 130px;
    text-align: center;
  }

  .menu-title {
    font-size: 18px;
  }

  .img-clock {
    width: 88px;
    height: 85px;
  }

  .konsumsi-section {
    height: 200px;
  }

  .img-obat {
    width: 65px;
    height: 100px;
  }

  .text-obat {
    font-size: 24px;
  }

  .konsumsi-kanan {
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
    padding: 50px 70px 50px 70px;
  }

  .article-item {
    width: 400px;
    height: 200px;
    border-radius: 20px;
    margin-top: 10px;
  }

  /* Menargetkan elemen checkbox internal secara global */
  :global(.ant-checkbox-inner) {
    width: 56px !important;
    /* Mengatur lebar */
    height: 56px !important;
    /* Mengatur tinggi */
    border-radius: 8px !important;
    /* Opsional: Menghapus border-radius */

    /* Center horizontal */
    border: 1px solid #707072 !important;
  }

  /* Opsi tambahan: Menyesuaikan ukuran tanda centang jika perlu */
  :global(.ant-checkbox-inner::after) {
    /* Atur lebar tanda centang */
    // width: 20px !important;
    // height: 20px !important;
    /* Atur tinggi tanda centang */
    /* Pusatkan secara vertikal */
    // border-radius: 8px !important;
    display: flex !important;
    /* Menggunakan flexbox untuk align centang */
    align-items: center !important;
    /* Center vertical */
    justify-content: center !important;
    top: 20px !important;
    left: 24px !important;
    transform: scale(3) !important;
    rotate: 40deg;
    /* Menyesuaikan posisi dan skala */
  }
}

//TABLET
@media screen and (max-width: 1439px) and (min-width: 768px) {
  .main-menu-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
  }

  .title-section {
    font-size: 32px;
    color: #4f125f;
    width: auto;
  }

  .img-banner {
    width: 200px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-title {
    font-size: 32px;
    // width: 130px;
    text-align: center;
  }

  .banner-title {
    font-size: 16px;
    width: 100%;
  }

  .custom-button-yes {
    height: 40px !important;
    width: 100%;
    font-size: 12px !important;
  }

  .custom-button-no {
    height: 30px !important;
    width: 100%;
    font-size: 12px !important;
    margin-top: 5px;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
  }

  .line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    background-color: #4f4f4f;
    height: 1px;
  }

  .img-step {
    width: 50px;
    height: 50px;
  }

  .step p {
    font-size: 12px;
  }

  .title-step {
    height: 240px;
  }

  .img-clock {
    width: 48px;
    height: 45px;
  }

  .konsumsi-section {
    height: 100px;
  }

  .article-item {
    width: 280px;
    height: 130px;
    border-radius: 20px;
    margin-top: 10px;
  }
}
</style>
